<template>
  <div>
    <b-form @submit.prevent="setPinCode">
      <div class="container d-flex align-items-start flex-column vh-100" fluid="lg">
        <div class="row mx-auto">
          <div class="col-12 pt-4">
            <h6 class="text-center">{{ $t('set_pincode') }}</h6>
          </div>

          <div class="col-12 pt-4 px-0 d-flex justify-content-center">
            <div style="display: flex">
              <OtpInput
                ref="otpInput"
                input-type="number"
                input-classes="otp-input"
                separator
                :num-inputs="6"
                :should-auto-focus="true"
                :is-input-num="true"
                :class="{ error: $v.pincode.$error }"
                @on-change="handleOnChange"
                @on-complete="handleOnComplete"
              />
              <div class="my-auto ml-2">
                <i
                  v-if="showPincode"
                  class="ri-eye-line ri-lg"
                  style="line-height: unset"
                  @click="showPincode = false"
                ></i>
                <i v-else class="ri-eye-off-line ri-lg" style="line-height: unset" @click="showPincode = true"></i>
              </div>
            </div>
          </div>
          <div class="col-12 pt-5">
            <div class="small text-primary text-center">
              {{ $t('set_pincode_content') }}
            </div>
          </div>
        </div>

        <div class="row mt-auto mx-auto">
          <div class="col-12 mb-4">
            <b-button type="submit" pill variant="primary px-4">{{ $t('continue') }}</b-button>
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import OtpInput from '@bachdgvn/vue-otp-input'
import { required, minLength } from 'vuelidate/lib/validators'
export default {
  components: { OtpInput },
  data() {
    return {
      pincode: null,
      showPincode: false,
      routeName: this.$router.currentRoute.name,
    }
  },
  watch: {
    showPincode: function (val) {
      if (val) {
        const inputs = this.$refs.otpInput.$el.querySelectorAll('.otp-input')
        inputs.forEach((i) => i.classList.remove('password-mask'))
      } else {
        const inputs = this.$refs.otpInput.$el.querySelectorAll('.otp-input')
        inputs.forEach((i) => i.classList.add('password-mask'))
      }
    },
  },
  mounted() {
    this.$refs.otpInput.$el.children[0].setAttribute('autocomplete', 'new-password')
    const inputs = this.$refs.otpInput.$el.querySelectorAll('.otp-input')
    inputs.forEach((i) => i.setAttribute('autocomplete', 'new-password'))
    inputs.forEach((i) => i.setAttribute('inputmode', 'numeric'))
    inputs.forEach((i) => i.classList.add('password-mask'))
  },
  methods: {
    handleOnComplete(value) {
      console.log(value)
    },
    handleOnChange(value) {
      this.pincode = value.replace(/[^0-9]+/g, '')
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput()
    },
    setPinCode() {
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        this.$store.dispatch('register/savePincode', {
          pincode: this.pincode,
        })
        if (this.routeName == 'forget-password-set-pincode') {
          this.$router.replace({ name: 'forget-password-confirm-pincode' })
        } else {
          this.$router.replace({ name: 'confirm-pincode' })
        }
      }
    },
  },
  validations: {
    pincode: {
      required,
      minLength: minLength(6),
    },
  },
}
</script>

<style>
.password-mask {
  -webkit-text-security: disc;
  -moz-webkit-text-security: disc;
  -moz-text-security: disc;
}
</style>
